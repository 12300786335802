<template>
    <div class="testimonals">
        <div class="header">
            <h1>Testimonials</h1>
            <p>This Is What Our Clients Have To Say</p>
        </div>
        <template v-if="seeMoreActivated">
            <div class="seemore-modal">
                <div class="seemore">
                    <span
                        @click="
                            activateSeeMore(
                                false,
                            )
                        "
                        class="close"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            fill="none"
                            viewBox="0 0 25 24"
                        >
                            <path
                                fill="#000"
                                d="M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"
                            />
                        </svg>
                    </span>
                    <p
                        class="seemore-heading"
                    >
                        Review
                    </p>
                    <p
                        class="seemore-text"
                    >
                        {{
                            message
                        }}
                    </p>
                </div>
            </div>
            
        </template>
        <template v-if="user.length > 0">
            <Carousel :settings="settings" style="margin-top: 80px">
                <div
                    class=""
                    v-for="(list, index) in user"
                    :key="list + '_' + index"
                >
                    <div class="testimonial-slide">
                        <div class="testimonial-content">
                            <div class="client-testimonial">
                                <div class="client-testimonial-card">
                                    <div class="client-info">
                                        <div class="client-img-wrapper">
                                            <img
                                                class="client-img"
                                                :src="list.user_image"
                                                :alt="list.name"
                                            />
                                        </div>
                                        <h1>{{ list.name }}</h1>
                                    </div>
                                    <div class="brief-testimonial">
                                        <template v-if="!seeMoreActivated">
                                             <p class="description">
                                                {{
                                                    list.msg.slice(
                                                        0,
                                                        220,
                                                    )
                                                }}...
                                                <span
                                                    class="more"
                                                    @click="
                                                        activateSeeMore(
                                                            true,list.msg
                                                        )
                                                    "
                                                    >see more</span
                                                >
                                            </p>
                                        </template>
                                        <template v-else>
                                             <p class="description">
                                                {{
                                                    list.msg.slice(
                                                        0,
                                                        220,
                                                    )
                                                }}...
                                            </p>
                                        </template>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="client-space-img">
                                <img :src="list.bg_image" />
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel>
        </template>
    </div>
</template>

<script>
import Carousel from 'componentsv2/Carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    name: 'TestimonialsCarousel',
    components: {
        Carousel,
    },
    props: ['user'],
    data() {
        return {
            settings: {
                dots: true,
                // autoplay: true,
                autoplaySpeed: 2800,
                arrows: false,
                prevArrow: false,
                focusOnSelect: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
            },
            seeMoreActivated: false,
            message:'',
        }
    },
    methods:{
        activateSeeMore(bool,msg) {
            this.seeMoreActivated = bool
            this.message=msg
            if (this.seeMoreActivated) {
                document.querySelector('.description').style.overflow = 'auto'
                document.querySelector('body').style.overflow = 'hidden'
            } else {
                document.querySelector('.description').style.overflow = 'hidden'
                document.querySelector('body').style.overflow = 'auto'
            }
        },
    }
}
</script>

<style lang="scss">
@import './TestimonailsCarousel.scss';
</style>
