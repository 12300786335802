import { getScenesCollection } from 'api/scenesApi'
import { getDesignersList } from 'api/designerApi'
import { getThemesList, getRoomCategoriesList } from 'api/themeApi'
import { getProductsList } from 'api/productsApi'
import { getTestimonialList } from 'api/userApi'
import { getVendorList } from 'api/vendorApi'

import { getSpecialScenes } from 'api/scenesApi'
import { getInspirationalIdeas } from 'api/designIdeasApi'

const HomePage = {
    state: () => ({
        scenesCollection: [],
        designersList: [],
        themesList: [],
        productsList: [],
        roomCategoriesList: [],
        testimonialList: [],
        specialScenes: [],
        inspirationalIdeas: [],
        vendorsList: [],
        brandList: [],
        quoteInfo: [],
    }),

    mutations: {
        setScenesCollection(state, payload) {
            state.scenesCollection = payload
        },
        setDesignersList(state, payload) {
            state.designersList = payload
        },
        setThemesList(state, payload) {
            state.themesList = payload
        },
        setProductsList(state, payload) {
            state.productsList = payload
        },
        setRoomCategoriesList(state, payload) {
            state.roomCategoriesList = payload
        },
        setTestimonialList(state, payload) {
            state.testimonialList = payload
        },
        setVendorsList(state, payload) {
            state.vendorsList = payload
        },
        setBrandList(state, payload) {
            state.brandList = payload
        },
        setQuoteInfo(state, payload) {
            state.quoteInfo = payload
        },
        setSpecialScenes(state, payload) {
            state.specialScenes = payload
        },
        setInspirationalIdeas(state, payload) {
            state.inspirationalIdeas = payload
        },
    },

    getters: {},

    actions: {
        fetchScenesCollection({ commit }) {
            return getScenesCollection()
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setScenesCollection', payload)
                        return payload
                    }
                })
        },
        fetchDesignersList({ commit }) {
            return getDesignersList()
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setDesignersList', payload)
                        return payload
                    }
                })
        },
        fetchThemesList({ commit }) {
            return getThemesList()
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setThemesList', payload)
                        return payload
                    }
                })
        },
        fetchProductsList({ commit }) {
            return getProductsList(
                {
                    'category_ids': null,
                    'tags_ids': null,
                    'search': null,
                    'vendor_ids': null,
                    'price': null,
                    'sortby': null,
                    'page': null
                }
            )
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setProductsList', payload)
                        return payload
                    }
                })
        },
        fetchRoomCategoriesList({ commit }) {
            return getRoomCategoriesList()
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setRoomCategoriesList', payload)
                        return payload
                    }
                })
        },
        fetchTestimonialList({ commit }) {
            return getTestimonialList()
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setTestimonialList', payload)
                        return payload
                    }
                })
        },
        fetchVendorsList({ commit }) {
            return getVendorList()
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setVendorsList', payload)
                        return payload
                    }
                })
        },
        fetchSpecialScenes({ commit }) {
            return getSpecialScenes()
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setSpecialScenes', payload)
                        return payload
                    }
                })
        },
        fetchInspirationalIdeas({ commit }) {
            return getInspirationalIdeas()
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setInspirationalIdeas', payload.data)
                        return payload.data
                    }
                })
        },

    }
}

export default HomePage;